import * as firestore from 'firebase/firestore';

export interface Entry {
  name: string;
  kana: string;
  zip: string;
  prefecture: string;
  municipality: string;
  address: string;
  age: string;
  gender: string;
  // email: string;
  tel: string;
  serialNum: string;
  campaignId: string;
  answer1: string[];
  answer2: string;
  answer3: string[];
  answer4: string;
  answer5: string;
  createdAt: firestore.Timestamp | firestore.FieldValue;
}

// TODO フォームの項目に合わせて修正
export interface EntryDto {
  name: string;
  kana: string;
  zip: string;
  prefecture: string;
  municipality: string;
  address: string;
  age: string;
  gender: string;
  // email: string;
  tel: string;
  serialNum: string;
  campaignId: string;
  answer1: string[];
  answer2: string;
  answer3: string[];
  answer4: string;
  answer5: string;
}

export interface EntryForm {
  name: string;
  kana: string;
  zip: string;
  prefecture: string;
  municipality: string;
  address: string;
  age: string;
  gender: string;
  // email: string;
  tel: string;
  serialNum: string;
  campaignId: string;
  answer1: { [key: string]: string };
  answer2: string;
  answer3: { [key: string]: string };
  answer4: string;
  answer5: string;
}
