// アンケート設問一覧
export const questionnaires: { question: string; answers: string[] }[] = [
  {
    question: 'この企画はどこで知りましたか？',
    answers: [
      'TVCM',
      '折込チラシ',
      'エレナ、なかよし村店内',
      'レジでのご案内(応募券をもらった)',
      'その他',
    ],
  },
  {
    question: 'エレナのカード(エレカ)またはアプリをお持ちですか?',
    answers: [
      'エレカカードをもっている',
      'エレカアプリをもっている',
      'どちらももっていない',
    ],
  },
  // {
  //   question: '本日のご利用店舗数をお教えください。',
  //   answers: ['1店舗', '2店舗', '3店舗', '4～5店舗', '6～9店舗', '10店舗以上'],
  // },
  // {
  //   question: '各ショップの10周年特別クーポンはご利用されましたか？',
  //   answers: ['YES', 'NO'],
  // },
];
