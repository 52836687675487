import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { StateService } from '../../core/service/state.service';

@Component({
  selector: 'web-entry-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  providers: [Title],
})
export class NotFoundComponent {
  serialNum: string | null = null;
  constructor(private title: Title, private stateService: StateService) {
    this.title.setTitle('エレナ - NotFound');
  }
}
